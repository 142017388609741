import { Link, useNavigate, useParams } from "react-router";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
  CButton,
  CButtonGroup,
  CLoadingButton,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import { formatCurrency } from "src/helpers/numbers";
import { Daruma, TiendaNube } from "src/components/Icon";
import {
  ApolloErrorWithData,
  GraphQLFind,
  GraphQLMeta,
  GraphQLMutation,
} from "src/types";
import Api from "src/api";
import PlanAlert from "src/containers/PlanAlert";
import { TiendaNubeProduct } from "src/api/tienda-nube";
import { useEffect, useMemo, useState } from "react";
import { Store } from "src/api/stores";
import { Product } from "src/api/products";
import { useProductError } from "src/hooks/useProductError";

enum TabIndex {
  All = 1,
  MissingInDaruma = 2,
  OutdatedSKU = 3,
  OutdatedPrice = 4,
  OutdatedStock = 5,
}

const TiendaNubeAudit = () => {
  const navigate = useNavigate();
  const params = useParams();
  const storeId = Number(params.storeId);
  const [currentTab, setCurrentTab] = useState<TabIndex>(TabIndex.All);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [downloadedIds, setDownloadedIds] = useState<number[]>([]);
  const { handleProductErrorMessage } = useProductError();

  if (!storeId) {
    navigate("/tienda-nube");
  }

  const queryParams = {
    variables: {
      filters: {
        storeId,
        limit: 0,
      },
    },
  };

  const { data: store } = useQuery<GraphQLFind<Store>>(Api.Stores.GET_STORE, {
    variables: {
      id: storeId,
    },
  });

  const { data: items, loading } = useQuery<GraphQLMeta<TiendaNubeProduct>>(
    Api.TiendaNube.LIST_PRODUCTS,
    {
      fetchPolicy: "no-cache",
      ...queryParams,
    }
  );

  const [syncProductMutation, { loading: updating }] = useMutation(
    Api.TiendaNube.SYNC_PRODUCT,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const [syncProductsMutation, { loading: updatings }] = useMutation(
    Api.TiendaNube.SYNC_PRODUCTS,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const [updateDarumaSKUMutation, { loading: updatingDarumaSKU }] = useMutation(
    Api.TiendaNube.UPDATE_SKU_FROM_TIENDA_NUBE,
    {
      onCompleted: () => {
        window.location.reload();
      },
      onError: (e) => {
        const { graphQLErrors } = e as ApolloErrorWithData<{
          data: {
            error: string;
            itemId: number;
            productId: number;
          }[];
        }>;

        if (
          graphQLErrors[0]?.data?.data?.some((a) => a.error === "sku_in_use")
        ) {
          alert(
            "El SKU al que quieres actualizar ya se encuentra en uso en otro producto."
          );
        } else {
          alert("Ha ocurrido un error. Vuelve a intentar.");
        }
      },
    }
  );

  const [updateDarumaSKUsMutation, { loading: updatingDarumaSKUs }] =
    useMutation(Api.TiendaNube.UPDATE_SKUS_FROM_TIENDA_NUBE, {
      onCompleted: () => {
        window.location.reload();
      },
    });

  const [updateTiendaNubeSKUMutation, { loading: updatingTiendaNubeSKU }] =
    useMutation(Api.TiendaNube.UPDATE_SKU_FROM_DARUMA, {
      onCompleted: () => {
        window.location.reload();
      },
    });

  const [updateTiendaNubeSKUsMutation, { loading: updatingTiendaNubeSKUs }] =
    useMutation(Api.TiendaNube.UPDATE_SKUS_FROM_DARUMA, {
      onCompleted: () => {
        window.location.reload();
      },
    });

  const [downloadMutation, { loading: downloading }] = useMutation(
    Api.TiendaNube.DOWNLOAD_TIENDA_NUBE_PRODUCT,
    {
      onCompleted: ({
        data,
      }: GraphQLMutation<{
        product: Product;
        tiendaNubeProduct: TiendaNubeProduct;
      }>) => {
        window.open(`/#/products/${data.product.id}`, "_blank");

        setDownloadedIds((prev) => [...prev, data.tiendaNubeProduct.variantId]);
      },
      onError: (error) => {
        handleProductErrorMessage(error);
      },
    }
  );

  useEffect(() => {
    setSelectedItems([]);
  }, [currentTab]);

  const productList = useMemo(() => {
    if (!items?.data.data) {
      return [];
    }

    if (currentTab === TabIndex.All) {
      return items?.data.data;
    }

    if (currentTab === TabIndex.MissingInDaruma) {
      return items?.data.data.filter(
        (item) => !item.priceListItem && !downloadedIds.includes(item.variantId)
      );
    }

    if (currentTab === TabIndex.OutdatedSKU) {
      return items?.data.data.filter((item) => {
        if (!item.priceListItem) {
          return false;
        }

        const sku =
          item.priceListItem.fraction?.sku ?? item.priceListItem.product.sku;

        return sku !== item.sku;
      });
    }

    if (currentTab === TabIndex.OutdatedPrice) {
      return items?.data.data.filter((item) => {
        if (!item.priceListItem) {
          return false;
        }

        const price =
          item.priceListItem.fraction?.price ?? item.priceListItem.price;

        return price !== item.price;
      });
    }

    if (currentTab === TabIndex.OutdatedStock) {
      return items?.data.data.filter((item) => {
        if (!item.priceListItem) {
          return false;
        }

        if (item.stock_management === false) {
          return false;
        }

        return (
          !item.priceListItem.fractionId && item.darumaStock !== item.stock
        );
      });
    }

    return [];
  }, [currentTab, downloadedIds, items?.data.data]);

  const rest: any[] = [];

  if (currentTab !== TabIndex.All) {
    rest.push({
      key: "actions",
      label: "Acciones",
      _props: { className: "text-right" },
      filter: false,
    });
  }

  const multiSelection = selectedItems.length > 0;
  const currentStore = store?.data;

  return (
    <>
      <PlanAlert />

      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="align-items-center justify-content-center">
                <CCol sm="6" xs="6" className="px-0">
                  {`Auditoría de Productos: Daruma <-> Tienda Nube: ${
                    currentStore?.name ?? ""
                  }`}
                </CCol>
                <CCol sm="6" xs="6" className="row justify-content-end"></CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm={12} className="flex">
                  <CButtonGroup size="sm" role="group">
                    <CButton
                      color="primary"
                      variant={
                        currentTab === TabIndex.All ? undefined : "outline"
                      }
                      onClick={() => {
                        setSelectedItems([]);
                        setCurrentTab(TabIndex.All);
                      }}
                    >
                      Todos los Productos
                    </CButton>
                    <CButton
                      color="primary"
                      variant={
                        currentTab === TabIndex.MissingInDaruma
                          ? undefined
                          : "outline"
                      }
                      onClick={() => {
                        setSelectedItems([]);
                        setCurrentTab(TabIndex.MissingInDaruma);
                      }}
                    >
                      Inexistentes en Daruma
                    </CButton>
                    <CButton
                      color="primary"
                      variant={
                        currentTab === TabIndex.OutdatedSKU
                          ? undefined
                          : "outline"
                      }
                      onClick={() => {
                        setSelectedItems([]);
                        setCurrentTab(TabIndex.OutdatedSKU);
                      }}
                    >
                      SKU desactualizados
                    </CButton>
                    <CButton
                      color="primary"
                      variant={
                        currentTab === TabIndex.OutdatedPrice
                          ? undefined
                          : "outline"
                      }
                      onClick={() => {
                        setSelectedItems([]);
                        setCurrentTab(TabIndex.OutdatedPrice);
                      }}
                    >
                      Precios desactualizados
                    </CButton>
                    <CButton
                      color="primary"
                      variant={
                        currentTab === TabIndex.OutdatedStock
                          ? undefined
                          : "outline"
                      }
                      onClick={() => {
                        setSelectedItems([]);
                        setCurrentTab(TabIndex.OutdatedStock);
                      }}
                    >
                      Stock desactualizado
                    </CButton>
                  </CButtonGroup>

                  <div className="flex justify-content-end">
                    {multiSelection && (
                      <>
                        {[
                          TabIndex.OutdatedPrice,
                          TabIndex.OutdatedStock,
                        ].includes(currentTab) && (
                          <CLoadingButton
                            color="info"
                            size="sm"
                            disabled={!multiSelection}
                            onClick={() => {
                              if (!updatings && multiSelection) {
                                syncProductsMutation({
                                  variables: {
                                    itemIds: productList
                                      .filter((p) =>
                                        selectedItems.includes(p.variantId)
                                      )
                                      .map((p) => p.priceListItem.id),
                                  },
                                });
                              }
                            }}
                          >
                            {updatings
                              ? "Actualizando..."
                              : currentTab === TabIndex.OutdatedPrice
                              ? "Actualizar Precios"
                              : "Actualizar Stock"}
                          </CLoadingButton>
                        )}

                        {currentTab === TabIndex.OutdatedSKU && (
                          <>
                            <CLoadingButton
                              color="info"
                              size="sm"
                              disabled={!multiSelection}
                              onClick={() => {
                                if (!updatingDarumaSKUs && multiSelection) {
                                  updateDarumaSKUsMutation({
                                    variables: {
                                      products: productList
                                        .filter((p) =>
                                          selectedItems.includes(p.variantId)
                                        )
                                        .map((item) => ({
                                          productId:
                                            item.priceListItem.productId,
                                          sku: item.sku,
                                        })),
                                    },
                                  });
                                }
                              }}
                            >
                              {updatingDarumaSKUs
                                ? "Actualizando..."
                                : "Actualizar en Daruma"}
                            </CLoadingButton>
                            <CLoadingButton
                              className="ml-2"
                              size="sm"
                              color="info"
                              disabled={!multiSelection}
                              onClick={() => {
                                if (!updatingTiendaNubeSKUs && multiSelection) {
                                  updateTiendaNubeSKUsMutation({
                                    variables: {
                                      storeId,
                                      products: productList
                                        .filter((p) =>
                                          selectedItems.includes(p.variantId)
                                        )
                                        .map((item) => ({
                                          productId: item.id,
                                          variantId: item.variantId,
                                          sku: item.priceListItem.product.sku,
                                        })),
                                    },
                                  });
                                }
                              }}
                            >
                              {updatingTiendaNubeSKUs
                                ? "Actualizando..."
                                : "Actualizar en Tienda Nube"}
                            </CLoadingButton>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </CCol>
              </CRow>

              <CSmartTable
                className="mt-2"
                itemsPerPage={20}
                items={productList.map((p) => ({
                  ...p,
                  _selectable:
                    currentTab === TabIndex.OutdatedStock
                      ? p.darumaStock >= 0
                      : true,
                }))}
                selected={productList.filter((p) =>
                  selectedItems.includes(p.variantId)
                )}
                selectable={
                  [
                    TabIndex.OutdatedSKU,
                    TabIndex.OutdatedPrice,
                    TabIndex.OutdatedStock,
                  ].includes(currentTab) && productList.length > 1
                }
                onSelectAll={() => {
                  setSelectedItems(
                    productList
                      .filter((p) =>
                        currentTab === TabIndex.OutdatedStock
                          ? p.darumaStock >= 0
                          : true
                      )
                      .map((item) => item.variantId)
                  );
                }}
                onSelectedItemsChange={(e) => {
                  setSelectedItems(e.map((i) => i.variantId));
                }}
                pagination
                columns={[
                  {
                    key: "name",
                    label: "Nombre",
                    _props: { className: "font-weight-bold" },
                  },
                  {
                    key: "stock",
                    label: "Stock",
                    _props: { className: "text-center" },
                    filter: false,
                  },
                  {
                    key: "cost",
                    label: "Costo",
                    _props: { className: "text-right" },
                    filter: false,
                  },
                  {
                    key: "price",
                    label: "Precio",
                    _props: { className: "text-right" },
                    filter: false,
                  },
                  ...rest,
                ]}
                columnFilter={{
                  lazy: true,
                }}
                loading={loading}
                scopedColumns={{
                  name: (item: TiendaNubeProduct) => (
                    <td>
                      <div className="flex align-items-center">
                        <Daruma />
                        <span className="ml-2">
                          {item.priceListItem ? (
                            <Link
                              to={`/products/${item.priceListItem.productId}`}
                              target="_blank"
                            >
                              {item.priceListItem.product.name} -{" "}
                              <small>
                                SKU: {item.priceListItem.product.sku}
                              </small>
                            </Link>
                          ) : (
                            "-"
                          )}
                        </span>
                      </div>
                      <div className="flex align-items-center mt-1">
                        <TiendaNube size={24} />
                        <Link to={item.url} target="_blank" className="ml-2">
                          {item.name} - <small>SKU: {item.sku}</small>
                        </Link>
                      </div>
                    </td>
                  ),
                  cost: (item: TiendaNubeProduct) => (
                    <td align="right">
                      <div className="flex justify-content-end">
                        {item.priceListItem ? (
                          <>
                            <Daruma />
                            <span className="ml-2">
                              {formatCurrency(item.priceListItem.product.cost)}
                            </span>
                          </>
                        ) : (
                          <span className="ml-2">-</span>
                        )}
                      </div>
                      <div className="flex justify-content-end mt-1">
                        <TiendaNube size={24} />
                        <span className="ml-2">
                          {formatCurrency(item.cost)}
                        </span>
                      </div>
                    </td>
                  ),
                  stock: (item: TiendaNubeProduct) => (
                    <td align="right">
                      <div className="flex justify-content-end">
                        {item.priceListItem ? (
                          <>
                            <Daruma />
                            <span className="ml-2">{item.darumaStock}</span>
                          </>
                        ) : (
                          <span className="ml-2">-</span>
                        )}
                      </div>
                      <div className="flex justify-content-end mt-1">
                        <TiendaNube size={24} />
                        <span className="ml-2">
                          {item.stock_management ? item.stock : "♾️"}
                        </span>
                      </div>
                    </td>
                  ),
                  price: (item: TiendaNubeProduct) => (
                    <td align="right">
                      <div className="flex justify-content-end">
                        {item.priceListItem ? (
                          <>
                            <Daruma />
                            <span className="ml-2">
                              {formatCurrency(item.priceListItem.price)}
                            </span>
                          </>
                        ) : (
                          <span className="ml-2">-</span>
                        )}
                      </div>
                      <div className="flex justify-content-end mt-1">
                        <TiendaNube size={24} />
                        <span className="ml-2">
                          {formatCurrency(item.price)}
                        </span>
                      </div>
                    </td>
                  ),
                  actions: (item: TiendaNubeProduct) => {
                    const hasDiffPrice =
                      item.priceListItem?.price !== item.price;
                    const hasDiffSku =
                      item.priceListItem?.product.sku !== item.sku;
                    const canUpdateStock =
                      item.stock_management === true && item.darumaStock >= 0;
                    const hasDiffStock =
                      canUpdateStock && item.stock !== item.darumaStock;
                    const isDownloaded = item.priceListItem !== null;

                    return (
                      <td align="right">
                        {currentTab === TabIndex.MissingInDaruma &&
                          !isDownloaded && (
                            <Link
                              to=""
                              onClick={() => {
                                if (!downloading) {
                                  downloadMutation({
                                    variables: {
                                      storeId,
                                      productId: item.id,
                                      variantId: item.variantId,
                                    },
                                  });
                                }
                              }}
                            >
                              <small>
                                {downloading ? "Descargando" : "Descargar"}
                              </small>
                            </Link>
                          )}

                        {currentTab === TabIndex.OutdatedSKU &&
                          isDownloaded &&
                          hasDiffSku && (
                            <div className="justify-content-end">
                              <div className="flex justify-content-end">
                                <Link
                                  to=""
                                  onClick={() => {
                                    if (
                                      !updatingDarumaSKU &&
                                      !updatingTiendaNubeSKU
                                    ) {
                                      updateDarumaSKUMutation({
                                        variables: {
                                          productId:
                                            item.priceListItem.productId,
                                          sku: item.sku,
                                        },
                                      });
                                    }
                                  }}
                                >
                                  <small>
                                    {updatingDarumaSKU
                                      ? "Actualizando"
                                      : "Actualizar en Daruma"}
                                  </small>
                                </Link>
                              </div>
                              <div className="flex justify-content-end mt-1">
                                <Link
                                  to=""
                                  onClick={() => {
                                    if (
                                      !updatingDarumaSKU &&
                                      !updatingTiendaNubeSKU
                                    ) {
                                      updateTiendaNubeSKUMutation({
                                        variables: {
                                          productId: item.id,
                                          variantId: item.variantId,
                                          storeId,
                                          sku: item.priceListItem.product.sku,
                                        },
                                      });
                                    }
                                  }}
                                >
                                  <small>
                                    {updatingTiendaNubeSKU
                                      ? "Actualizando"
                                      : "Actualizar en Tienda Nube"}
                                  </small>
                                </Link>
                              </div>
                            </div>
                          )}

                        {currentTab === TabIndex.OutdatedPrice &&
                          isDownloaded &&
                          hasDiffPrice && (
                            <Link
                              to=""
                              onClick={() => {
                                if (!updating && !multiSelection) {
                                  syncProductMutation({
                                    variables: {
                                      itemId: item.priceListItem.id,
                                    },
                                  });
                                }
                              }}
                              style={{ color: multiSelection ? "gray" : "" }}
                            >
                              <small>
                                {updating
                                  ? "Actualizando"
                                  : "Actualizar Precio"}
                              </small>
                            </Link>
                          )}

                        {currentTab === TabIndex.OutdatedStock &&
                          isDownloaded &&
                          hasDiffStock && (
                            <Link
                              to=""
                              onClick={() => {
                                if (!updating && !multiSelection) {
                                  syncProductMutation({
                                    variables: {
                                      itemId: item.priceListItem.id,
                                    },
                                  });
                                }
                              }}
                              style={{ color: multiSelection ? "gray" : "" }}
                            >
                              <small>
                                {updating ? "Actualizando" : "Actualizar Stock"}
                              </small>
                            </Link>
                          )}
                      </td>
                    );
                  },
                }}
                tableProps={{
                  striped: true,
                  hover: true,
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default TiendaNubeAudit;
