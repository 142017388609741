import { useQuery } from "@apollo/client";
import { CAlert, CRow } from "@coreui/react-pro";
import { Link } from "react-router";
import Api from "src/api";
import { Company, PaymentStatus } from "src/api/companies";
import { PlanType } from "src/api/plans";
import { getCompanyPaymentStatus } from "src/helpers/payments";
import { useUserStore } from "src/store/users";
import { GraphQLFind } from "src/types";

const FeatureAlert = () => {
  const { currentCompany } = useUserStore();

  const { data: company } = useQuery<GraphQLFind<Company>>(
    Api.Companies.GET_COMPANY,
    {
      skip: !currentCompany?.id,
      fetchPolicy: "no-cache",
      variables: {
        id: currentCompany?.id,
      },
    }
  );

  if (!company?.data) {
    return null;
  }

  const planType = company.data.currentPlan.type;

  const isWarning =
    getCompanyPaymentStatus(company.data.paymentStatus) ===
    PaymentStatus.WARNING;

  const isUnpaid =
    getCompanyPaymentStatus(company.data.paymentStatus) ===
    PaymentStatus.UNPAID;

  const isDebtor =
    getCompanyPaymentStatus(company.data.paymentStatus) ===
    PaymentStatus.DEBTOR;

  const shouldDisplay =
    planType !== PlanType.Free && (isWarning || isUnpaid || isDebtor);

  if (!shouldDisplay) {
    return null;
  }

  return (
    <CRow className="mx-0 mb-3 mt-2">
      <CAlert color={isWarning ? "warning" : "danger"} className="mb-0">
        {isWarning && (
          <>
            ¡Hola! Recuerda que esta funcionalidad es de nuestros planes pagos y
            tu plan está por vencer en pocos días. Para que no pierdas acceso a
            la misma, te sugerimos que regularices tu situación. <ToPayLink />
            <br /> ¡Gracias por confiar en nosotros!
          </>
        )}

        {isUnpaid && (
          <>
            ¡Hola! Recuerda que esta funcionalidad es de nuestros planes pagos y
            actualmente, tu plan está vencido. Para que no te veas afectado, la
            funcionalidad básica sigue operativa, pero algunas de las funciones
            extras se encuentran deshabilitadas, te sugerimos que regularices tu
            situación para recuperar la funcionalidad por completo. Pedimos
            sepan entender y disculpen las molestias. <ToPayLink />
            <br /> ¡Gracias por confiar en nosotros!
          </>
        )}

        {isDebtor && (
          <>
            ¡Hola! Como ya ha sido notificado previamente, esta funcionalidad es
            de nuestros planes pagos. A día de hoy llevas varios días de retraso
            en tus pagos. Debido esto, esta funcionalidad se ha deshabilitado
            por completo. Para recuperar las funcionalidades que proporciona te
            sugerimos que regularices tu situación. <ToPayLink />
            <br /> ¡Gracias por confiar en nosotros!
          </>
        )}
      </CAlert>
    </CRow>
  );
};

export const ToPayLink = () => {
  return (
    <>
      Recuerda que ahora puedes pagar a traves de Mercado Pago haciendo{" "}
      <Link to="/plan">click aqui</Link>
    </>
  );
};

export default FeatureAlert;
